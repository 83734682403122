






import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import SwabSampleAnalysisChartHeader from './SwabSampleAnalysisChartHeader.vue';
import SwabSampleAnalysisChartBody from './SwabSampleAnalysisChartBody.vue';

@Component({
  components: {
    SwabSampleAnalysisChartHeader,
    SwabSampleAnalysisChartBody
  }
})
export default class SwabSampleAnalysisChart extends Vue {
}
