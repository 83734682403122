










import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import EmptyAnalysisPlaceholder from '../EmptyAnalysisPlaceholder.vue';
import SwabSampleAnalysisPageHeader from './SwabSampleAnalysisPageHeader.vue';
import { analysisStores } from '@/store/poultry/analysis';
import SwabSampleAnalysisChart from './SwabSampleAnalysisChart/SwabSampleAnalysisChart.vue';
import SwabSampleAnalysisTable from './SwabSampleAnalysisTable/SwabSampleAnalysisTable.vue';

@Component({
  components: {
    SwabSampleAnalysisPageHeader,
    SwabSampleAnalysisChart,
    SwabSampleAnalysisTable,
    EmptyAnalysisPlaceholder 
  }
})
export default class SwabSampleAnalysis extends Vue {
  //TODO
  store = analysisStores.swab_sample.list;

  created() {
    this.store.setSwabSampleCreated(false);
  }

  get isSwabSampleCreated() {
    return this.store.isCreated;
  }
}
