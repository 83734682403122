import { render, staticRenderFns } from "./SwabSampleAnalysisTableBody.vue?vue&type=template&id=91525322&scoped=true&"
import script from "./SwabSampleAnalysisTableBody.vue?vue&type=script&lang=ts&"
export * from "./SwabSampleAnalysisTableBody.vue?vue&type=script&lang=ts&"
import style0 from "./SwabSampleAnalysisTableBody.vue?vue&type=style&index=0&id=91525322&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91525322",
  null
  
)

export default component.exports