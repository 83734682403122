








import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import SwabSampleAnalysisTableHeader from './SwabSampleAnalysisTableHeader.vue';
import SwabSampleAnalysisTableBody from './SwabSampleAnalysisTableBody.vue';

@Component({
  components: {
    SwabSampleAnalysisTableHeader,
    SwabSampleAnalysisTableBody,
  },
})
export default class SwabSampleAnalysisTable extends Vue {}
